"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ptBR = void 0;
exports.ptBR = {
    knowledgeBaseTitle: 'Base de Conhecimento',
    contactUs: 'Entre em Contato',
    searchPlaceholder: 'Pesquisar ajuda',
    files: 'Arquivos',
    mb: 'MB',
    kb: 'KB',
    writeToUs: 'Escreva para nós',
    namePlaceholder: 'Qual é o seu nome?',
    emailPlaceholder: 'E-mail',
    messagePlaceholder: 'Texto da mensagem',
    selectFiles: 'Selecionar arquivos',
    orDropFiles: ' ou arraste-os aqui',
    maxFileSizeError: 'Tamanho dos arquivos excedido o limite de {maxSize} MB',
    send: 'Enviar',
    continue: 'Continuar',
    back: 'Voltar',
    operator: 'Operador',
    successTitle: 'Sua mensagem foi enviada!',
    successText: 'Responderemos em breve',
    onlineStatus: 'Online',
    messageDeleted: 'Mensagem excluída',
    replay: 'Responder',
    delete: 'Excluir',
    chatInputPlaceholder: 'Escreva uma mensagem...',
    deleteModalTitle: 'Excluir',
    deleteModalText: 'Tem certeza de que deseja excluir a mensagem?',
    cancel: 'Cancelar',
    sizeExceededModalTitle: 'Arquivo muito grande',
    sizeExceededModalText: 'Não é possível enviar arquivos com mais de {maxSize} MB',
    ok: 'Ok',
    file: 'Arquivo',
    footer: 'Desenvolvido por ',
};
