// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".headerContainer__g8XJj {\n  display: flex;\n  gap: 10px;\n  align-items: flex-end;\n  justify-content: space-between;\n}\n\n.redirect__jHQUA {\n  cursor: pointer;\n\n  margin-right: 20px;\n\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 18px;\n  color: #fff;\n}\n\n.redirect__jHQUA span:hover {\n  text-decoration: underline;\n}\n", "",{"version":3,"sources":["webpack://./src/common/components/Header/Header.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,qBAAqB;EACrB,8BAA8B;AAChC;;AAEA;EACE,eAAe;;EAEf,kBAAkB;;EAElB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".headerContainer {\n  display: flex;\n  gap: 10px;\n  align-items: flex-end;\n  justify-content: space-between;\n}\n\n.redirect {\n  cursor: pointer;\n\n  margin-right: 20px;\n\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 18px;\n  color: #fff;\n}\n\n.redirect span:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
export var headerContainer = "headerContainer__g8XJj";
export var redirect = "redirect__jHQUA";
export default ___CSS_LOADER_EXPORT___;
