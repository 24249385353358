"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.en = void 0;
exports.en = {
    knowledgeBaseTitle: 'Knowledge base',
    contactUs: 'Contact Us',
    searchPlaceholder: 'Search for help',
    files: 'Files',
    mb: 'MB',
    kb: 'KB',
    writeToUs: 'Write to us',
    namePlaceholder: 'What’s Your Name?',
    emailPlaceholder: 'Email',
    messagePlaceholder: 'Message text',
    selectFiles: 'Select files',
    orDropFiles: ' or drop them here',
    maxFileSizeError: 'Files size exceeded {maxSize} MB limit',
    send: 'Send',
    continue: 'Continue',
    back: 'Back',
    operator: 'Operator',
    successTitle: 'Your message has been sent!',
    successText: 'We will respond soon',
    onlineStatus: 'Online',
    messageDeleted: 'Message deleted',
    replay: 'Replay',
    delete: 'Delete',
    chatInputPlaceholder: 'Write a message...',
    deleteModalTitle: 'Delete',
    deleteModalText: 'Are you sure you want to delete the message?',
    cancel: 'Cancel',
    sizeExceededModalTitle: 'File too large',
    sizeExceededModalText: "Can't send files over {maxSize} MB",
    ok: 'Ok',
    file: 'File',
    footer: 'Powered by ',
};
