"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.typedLocalStorage = void 0;
var localStorageKeys = {
    userData: 'userData',
    refererHost: 'refererHost',
};
exports.typedLocalStorage = {
    setItem: function (key, value) {
        localStorage.setItem(key, value);
    },
    getItem: function (key) {
        return localStorage.getItem(key) || null;
    },
};
