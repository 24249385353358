"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMessageStyles = void 0;
var getMessageStyles = function (message, index, messagesArr) {
    var prevMessage = index > 0 ? messagesArr[index - 1] : null;
    var nextMessage = index < messagesArr.length - 1 ? messagesArr[index + 1] : null;
    var isFirst = prevMessage === null || prevMessage.channel !== message.channel;
    var isLast = nextMessage === null || nextMessage.channel !== message.channel;
    var isAdmin = message.channel === 'admin';
    var showMessageTime = nextMessage === null || nextMessage.channel !== message.channel;
    var marginTop = isFirst ? '16px' : '6px';
    var borderRadius = '16px';
    if (isFirst && !isLast)
        borderRadius = isAdmin ? '16px 16px 16px 6px' : '16px 16px 6px 16px';
    if (!isFirst && !isLast)
        borderRadius = isAdmin ? '6px 16px 16px 6px' : '16px 6px 6px 16px';
    if (!isFirst && isLast)
        borderRadius = isAdmin ? '6px 16px 16px 16px' : '16px 6px 16px 16px';
    return { borderRadius: borderRadius, marginTop: marginTop, showMessageTime: showMessageTime };
};
exports.getMessageStyles = getMessageStyles;
