"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArticlePage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var material_1 = require("@mui/material");
var App_1 = require("../../App");
var OpenArticleIcon_1 = require("../OpenArticleIcon");
var ArticleFiles_1 = require("./ArticleFiles");
var styles = __importStar(require("./ArticlePage.module.css"));
var ArticlePage = function (_a) {
    var articleId = _a.articleId, arrowColor = _a.arrowColor, setArticleId = _a.setArticleId;
    var api = (0, react_1.useContext)(App_1.WidgetContext).api;
    var _b = (0, react_1.useState)(false), showAnimation = _b[0], setShowAnimation = _b[1];
    var _c = (0, react_1.useState)(null), article = _c[0], setArticle = _c[1];
    /** Позволяет открывать любые ссылки из статьи в новой вкладке, а не внутри iframe */
    var onLinkClick = (0, react_1.useCallback)(function (event) {
        event.preventDefault();
        var target = event.currentTarget;
        window.open(target.href, '_blank');
    }, []);
    (0, react_1.useEffect)(function () {
        var links = document.querySelectorAll('a');
        links.forEach(function (link) { return link.addEventListener('click', onLinkClick); });
        return function () { return links.forEach(function (link) { return link.removeEventListener('click', onLinkClick); }); };
    }, [article, onLinkClick]);
    (0, react_1.useEffect)(function () {
        if (!api)
            return;
        api.getArticle(articleId).then(function (res) {
            setArticle(res);
            setShowAnimation(true);
        });
    }, [api, articleId]);
    var onArticleHeader = function () {
        setShowAnimation(false);
        setTimeout(function () { return setArticleId(null); }, 300);
    };
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: styles.articlePageWrapper }, { children: article && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: styles.articlePageHeader, onClick: onArticleHeader }, { children: [(0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(OpenArticleIcon_1.ArticleArrowIcon, { color: arrowColor }) }), (0, jsx_runtime_1.jsx)("span", __assign({ title: article.title }, { children: article.title }))] })), (0, jsx_runtime_1.jsx)(material_1.Slide, __assign({ direction: 'left', in: showAnimation }, { children: (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("div", { className: styles.articleContent, dangerouslySetInnerHTML: { __html: article.content } }), (0, jsx_runtime_1.jsx)(ArticleFiles_1.ArticleFiles, { files: article.files })] }) }))] })) })));
};
exports.ArticlePage = ArticlePage;
