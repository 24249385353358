"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLastTimeInMessageGroup = void 0;
/** Получает значение времени (поле created_at) у последнего сообщения в группе.
 * Группа - это несколько сообщений подряд с одинаковым channel ('admin' | 'widget').
 * Это нужно для того, чтобы корректно определять ширину блока со временем в каждом сообщении, в компоненте Message.
 */
var getLastTimeInMessageGroup = function (currentMessageIndex, allMessages) {
    var currentMessage = allMessages[currentMessageIndex];
    var lastGroupMessage = allMessages.find(function (message, i) {
        var _a;
        if (i < currentMessageIndex)
            return false;
        if (((_a = allMessages[i + 1]) === null || _a === void 0 ? void 0 : _a.channel) !== currentMessage.channel)
            return true;
    });
    return (lastGroupMessage === null || lastGroupMessage === void 0 ? void 0 : lastGroupMessage.created_at) || '';
};
exports.getLastTimeInMessageGroup = getLastTimeInMessageGroup;
