"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.localization = void 0;
var en_1 = require("./en");
var ru_1 = require("./ru");
var pt_BR_1 = require("./pt-BR");
var it_1 = require("./it");
var sk_1 = require("./sk");
exports.localization = (_a = {
        en: en_1.en,
        ru: ru_1.ru
    },
    _a['pt-BR'] = pt_BR_1.ptBR,
    _a.it = it_1.it,
    _a.sk = sk_1.sk,
    _a);
