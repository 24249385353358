"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocketService = exports.WidgetContext = exports.App = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_intl_1 = require("react-intl");
var styles_1 = require("@mui/material/styles");
var common_1 = require("../../common");
var WriteToUsForm_1 = require("../WriteToUsForm");
var Chat_1 = require("../Chat");
var KnowledgeBase_1 = require("../KnowledgeBase");
var locales_1 = require("../../locales");
var typedLocalStorage_1 = require("../../utils/typedLocalStorage");
var widgetContext_1 = require("./services/widgetContext");
Object.defineProperty(exports, "WidgetContext", { enumerable: true, get: function () { return widgetContext_1.WidgetContext; } });
var handleFrameMessages_1 = require("./services/handleFrameMessages");
var socketService_1 = require("./services/socketService");
Object.defineProperty(exports, "SocketService", { enumerable: true, get: function () { return socketService_1.SocketService; } });
var getLanguage_1 = require("./services/getLanguage");
var defaultMuiTheme_1 = require("./defaultMuiTheme");
var styles = __importStar(require("./App.module.css"));
var App = function () {
    var _a = (0, react_1.useState)(), currentWidgetState = _a[0], setCurrentWidgetState = _a[1];
    var _b = (0, react_1.useState)(), widgetSettings = _b[0], setWidgetSettings = _b[1];
    var _c = (0, react_1.useState)(), api = _c[0], setApi = _c[1];
    var _d = (0, react_1.useState)(), socketService = _d[0], setSocketService = _d[1];
    var _e = (0, react_1.useState)(''), newUserData = _e[0], setNewUserData = _e[1];
    var _f = (0, react_1.useState)({
        isDisabledNameAndEmail: false,
        customFields: [],
    }), customSettings = _f[0], setCustomSettings = _f[1];
    var wrapperRef = (0, react_1.useRef)(null);
    var widgetBackgroundColor = (widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.backgroundColor) || common_1.DEFAULT_BACKGROUND_COLOR;
    var lang = (0, getLanguage_1.getLanguage)(widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.language, widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.application);
    var stateComponent = {
        form: (0, jsx_runtime_1.jsx)(WriteToUsForm_1.WriteToUsForm, { isFullForm: (widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.blocks.includes('form')) || false }),
        chat: (0, jsx_runtime_1.jsx)(Chat_1.Chat, {}),
        knowledge: (0, jsx_runtime_1.jsx)(KnowledgeBase_1.KnowledgeBase, {}),
    };
    (0, react_1.useEffect)(function () {
        var onFrameMessage = (0, handleFrameMessages_1.initHandleFrameMessages)({
            setWidgetSettings: setWidgetSettings,
            setApi: setApi,
            setCurrentWidgetState: setCurrentWidgetState,
            setSocketService: setSocketService,
            setNewUserData: setNewUserData,
            setCustomSettings: setCustomSettings,
        });
        window.addEventListener('message', onFrameMessage);
        return function () { return window.removeEventListener('message', onFrameMessage); };
    }, []);
    (0, react_1.useEffect)(function () {
        if (wrapperRef.current) {
            var wrapperElement = wrapperRef.current;
            wrapperElement.style.setProperty('--background', widgetBackgroundColor);
        }
    }, [widgetBackgroundColor]);
    (0, react_1.useEffect)(function () {
        if (!socketService)
            return;
        var eventListener = { 'join response': function () { return setCurrentWidgetState(common_1.WIDGET_STATE.CHAT); } };
        socketService.addEventListener(eventListener);
        return function () { return socketService.removeEventListener(eventListener); };
    }, [socketService]);
    var onRedirectToState = function (redirectState) {
        if (redirectState === common_1.WIDGET_STATE.WRITE_TO_US_FORM && (widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.blocks.includes('chat'))) {
            var userData = typedLocalStorage_1.typedLocalStorage === null || typedLocalStorage_1.typedLocalStorage === void 0 ? void 0 : typedLocalStorage_1.typedLocalStorage.getItem('userData');
            var joinResponseData = socketService === null || socketService === void 0 ? void 0 : socketService.joinResponseData;
            if (!userData)
                setCurrentWidgetState(common_1.WIDGET_STATE.WRITE_TO_US_FORM);
            if (!joinResponseData && userData)
                socketService === null || socketService === void 0 ? void 0 : socketService.registerChat(JSON.parse(userData));
            if (joinResponseData && userData)
                setCurrentWidgetState(common_1.WIDGET_STATE.CHAT);
            return;
        }
        setCurrentWidgetState(redirectState);
    };
    return ((0, jsx_runtime_1.jsx)(react_intl_1.IntlProvider, __assign({ messages: locales_1.localization[lang], locale: lang, defaultLocale: 'en' }, { children: (0, jsx_runtime_1.jsx)(styles_1.ThemeProvider, __assign({ theme: defaultMuiTheme_1.theme }, { children: (0, jsx_runtime_1.jsx)(widgetContext_1.WidgetContext.Provider, __assign({ value: {
                    redirectToState: onRedirectToState,
                    api: api,
                    widgetSettings: widgetSettings,
                    socketService: socketService,
                    newUserData: newUserData,
                    customSettings: customSettings,
                } }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: styles.wrapper, ref: wrapperRef }, { children: currentWidgetState && stateComponent[currentWidgetState] })) })) })) })));
};
exports.App = App;
