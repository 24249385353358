"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.widgetSettingsMapper = void 0;
exports.widgetSettingsMapper = {
    mapFrom: function (widgetSettingsDTO) {
        return {
            backgroundColor: widgetSettingsDTO.background_color,
            blocks: widgetSettingsDTO.blocks,
            fileUploadBorderColor: widgetSettingsDTO.file_upload_border_color,
            fontColor: widgetSettingsDTO.font_color,
            fontHoverColor: widgetSettingsDTO.font_hover_color,
            formButtonColor: widgetSettingsDTO.form_button_color,
            formButtonHoverColor: widgetSettingsDTO.form_button_hover_color,
            formButtonTitle: widgetSettingsDTO.form_button_title,
            formButtonTitleColor: widgetSettingsDTO.form_button_title_color,
            formTitle: widgetSettingsDTO.form_title,
            inputErrorColor: widgetSettingsDTO.input_error_color,
            instructionIconColor: widgetSettingsDTO.instruction_icon_color,
            openButtonColor: widgetSettingsDTO.open_button_color,
            openButtonHorizontalAlignment: widgetSettingsDTO.open_button_horizontal_alignment,
            openButtonImage: widgetSettingsDTO.open_button_image,
            openButtonImageHover: widgetSettingsDTO.open_button_image_hover,
            openButtonText: widgetSettingsDTO.open_button_text,
            openButtonTextColor: widgetSettingsDTO.open_button_text_color,
            openButtonVerticalAlignment: widgetSettingsDTO.open_button_vertical_alignment,
            showFormIfStaffsOffline: widgetSettingsDTO.show_form_if_staffs_offline,
            writeUsButtonTitle: widgetSettingsDTO.write_us_button_title,
            disableGoogleCaptcha: widgetSettingsDTO.disable_google_capthca,
            isDisabledNameAndEmail: widgetSettingsDTO.is_disabled_name_and_email,
        };
    },
};
