// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".searchInput__Z6S0K.searchInput__Z6S0K,\n.searchInput__Z6S0K.searchInput__Z6S0K input::placeholder {\n  width: 100%;\n\n  font-size: 14px;\n  line-height: 20px;\n  color: #fff;\n\n  opacity: 1;\n}\n\n.searchWrapper__DQ36b {\n  display: flex;\n  justify-content: space-between;\n\n  height: 21px;\n  padding: 12px 17px 12px 20px;\n\n  background: rgb(255 255 255 / 20%);\n}\n", "",{"version":3,"sources":["webpack://./src/components/KnowledgeBase/SearchInput/SearchInput.module.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;;EAEX,eAAe;EACf,iBAAiB;EACjB,WAAW;;EAEX,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,8BAA8B;;EAE9B,YAAY;EACZ,4BAA4B;;EAE5B,kCAAkC;AACpC","sourcesContent":[".searchInput.searchInput,\n.searchInput.searchInput input::placeholder {\n  width: 100%;\n\n  font-size: 14px;\n  line-height: 20px;\n  color: #fff;\n\n  opacity: 1;\n}\n\n.searchWrapper {\n  display: flex;\n  justify-content: space-between;\n\n  height: 21px;\n  padding: 12px 17px 12px 20px;\n\n  background: rgb(255 255 255 / 20%);\n}\n"],"sourceRoot":""}]);
// Exports
export var searchInput = "searchInput__Z6S0K";
export var searchWrapper = "searchWrapper__DQ36b";
export default ___CSS_LOADER_EXPORT___;
