"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initApi = void 0;
var lodash_1 = require("lodash");
var typedLocalStorage_1 = require("../utils/typedLocalStorage");
var modals_ts_1 = require("./modals.ts");
var handleResponse = function (response) { return __awaiter(void 0, void 0, void 0, function () {
    var error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!!response.ok) return [3 /*break*/, 2];
                return [4 /*yield*/, response.text()];
            case 1:
                error = _a.sent();
                throw new Error(error);
            case 2: return [2 /*return*/, response.json()];
        }
    });
}); };
var initApi = function (clientId) {
    var modifiedInit = {
        headers: {
            'client-id': String(clientId),
        },
    };
    return {
        getArticlesList: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("/widget/instruction", modifiedInit)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, handleResponse(response)];
                }
            });
        }); },
        getArticle: function (articleId) { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("/widget/instruction/".concat(articleId), modifiedInit)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, handleResponse(response)];
                }
            });
        }); },
        findArticles: function (searchText) { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("/widget/instruction?text=".concat(encodeURIComponent(searchText)), modifiedInit)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, handleResponse(response)];
                }
            });
        }); },
        createIssue: function (issueData) { return __awaiter(void 0, void 0, void 0, function () {
            var formData, referrerFromWindow, response;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        formData = new FormData();
                        formData.append('name', issueData.name);
                        formData.append('email', issueData.email);
                        formData.append('description', issueData.description);
                        formData.append('g_recaptcha_response', issueData.g_recaptcha_response);
                        formData.append('clientId', String(clientId));
                        formData.append('title', "".concat(issueData.name, " / Web Widget / ").concat(new Date().toLocaleString()));
                        referrerFromWindow = typedLocalStorage_1.typedLocalStorage.getItem('refererHost') || 'not recognized';
                        formData.append('referer', "".concat(referrerFromWindow));
                        (_a = issueData.files) === null || _a === void 0 ? void 0 : _a.forEach(function (file, index) { return formData.append("files[".concat(index, "]"), file); });
                        !(0, lodash_1.isEmpty)(issueData.custom_fields) && (0, modals_ts_1.addCustomFieldsInFormData)(formData, issueData);
                        return [4 /*yield*/, fetch("/issue/create", {
                                method: 'POST',
                                body: formData,
                                headers: __assign({}, modifiedInit.headers),
                            })];
                    case 1:
                        response = _b.sent();
                        return [2 /*return*/, handleResponse(response)];
                }
            });
        }); },
        getChatMessages: function (paramData) { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("/widget/message?".concat(new URLSearchParams(paramData)), modifiedInit)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, handleResponse(response)];
                }
            });
        }); },
        sendFiles: function (files) { return __awaiter(void 0, void 0, void 0, function () {
            var filesData, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        filesData = new FormData();
                        files.forEach(function (file, index) { return filesData.append("file[".concat(index, "]"), file); });
                        return [4 /*yield*/, fetch("/api/file", {
                                method: 'POST',
                                body: filesData,
                                headers: __assign({}, modifiedInit.headers),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, handleResponse(response)];
                }
            });
        }); },
    };
};
exports.initApi = initApi;
