"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Message = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_intl_1 = require("react-intl");
var material_1 = require("@mui/material");
var common_1 = require("../../../common");
var getMessageStyles_1 = require("./helpers/getMessageStyles");
var DeleteMessageModal_1 = require("./DeleteMessageModal");
var MessageBlocks_1 = require("./MessageBlocks");
var linkify_1 = require("./helpers/linkify");
var styles = __importStar(require("./Message.module.css"));
var Message = function (_a) {
    var message = _a.message, index = _a.index, messagesArr = _a.messagesArr, backgroundColor = _a.backgroundColor, clientId = _a.clientId, lastTimeInMessageGroup = _a.lastTimeInMessageGroup, onDeleteMessage = _a.onDeleteMessage, onReplayMessage = _a.onReplayMessage, setLoadedImages = _a.setLoadedImages;
    var intl = (0, react_intl_1.useIntl)();
    var _b = (0, react_1.useState)(null), contextMenu = _b[0], setContextMenu = _b[1];
    var _c = (0, react_1.useState)(false), isOpenDeleteModal = _c[0], setIsOpenDeleteModal = _c[1];
    var _d = (0, getMessageStyles_1.getMessageStyles)(message, index, messagesArr), borderRadius = _d.borderRadius, marginTop = _d.marginTop, showMessageTime = _d.showMessageTime;
    var userMessageColor = message.channel === 'widget' ? backgroundColor || common_1.DEFAULT_BACKGROUND_COLOR : undefined;
    var onContextMenu = function (e) {
        e.preventDefault();
        setContextMenu(contextMenu === null ? { mouseX: e.clientX + 2, mouseY: e.clientY - 6 } : null);
    };
    var onShowDeleteModal = function () {
        setContextMenu(null);
        setIsOpenDeleteModal(true);
    };
    var onDelete = function () {
        setIsOpenDeleteModal(false);
        onDeleteMessage(message.id);
    };
    var onReplay = function () {
        setContextMenu(null);
        onReplayMessage(__assign(__assign({}, message), { clientId: clientId }));
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: message.channel === 'widget' ? styles.userMessage : styles.adminMessage, style: { marginTop: marginTop, cursor: 'context-menu' }, onContextMenu: onContextMenu, onDoubleClick: onReplay }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: styles.message, style: { borderRadius: borderRadius, backgroundColor: userMessageColor } }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: styles.messageText }, { children: (0, linkify_1.linkify)(message.text) })), (0, jsx_runtime_1.jsx)(MessageBlocks_1.FilesBlock, { message: message, clientId: clientId, setLoadedImages: setLoadedImages }), (0, jsx_runtime_1.jsx)(MessageBlocks_1.ReplayBlock, { message: message })] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: styles.timeContainer }, { children: (0, jsx_runtime_1.jsx)("span", __assign({ className: styles.time, style: { visibility: showMessageTime ? 'visible' : 'hidden' } }, { children: (0, common_1.formatDate)(lastTimeInMessageGroup) })) })), (0, jsx_runtime_1.jsxs)(material_1.Menu, __assign({ open: contextMenu !== null, onClose: function () { return setContextMenu(null); }, className: styles.menu, anchorReference: 'anchorPosition', anchorPosition: contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined }, { children: [(0, jsx_runtime_1.jsx)(material_1.MenuItem, __assign({ onClick: onReplay }, { children: intl.formatMessage({ id: 'replay' }) })), message.channel === 'widget' && ((0, jsx_runtime_1.jsx)(material_1.MenuItem, __assign({ onClick: onShowDeleteModal }, { children: intl.formatMessage({ id: 'delete' }) })))] }))] })), (0, jsx_runtime_1.jsx)(DeleteMessageModal_1.DeleteMessageModal, { isOpen: isOpenDeleteModal, setIsOpen: setIsOpenDeleteModal, onDelete: onDelete })] }));
};
exports.Message = Message;
