"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
var material_1 = require("@mui/material");
exports.theme = (0, material_1.createTheme)({
    typography: { fontFamily: 'Inter, sans-serif' },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '@global': { body: { fontFamily: 'Inter, sans-serif', fontWeight: 400 } },
            },
        },
    },
});
