"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFileSizeFormatter = void 0;
var react_intl_1 = require("react-intl");
var useFileSizeFormatter = function () {
    var intl = (0, react_intl_1.useIntl)();
    return function (sizeInBytes) {
        var sizeInKb = sizeInBytes / 1024;
        if (sizeInKb >= 1024) {
            var sizeInMb = sizeInKb / 1024;
            return "".concat(sizeInMb.toFixed(2), " ").concat(intl.formatMessage({ id: 'mb' }));
        }
        return "".concat(sizeInKb.toFixed(2), " ").concat(intl.formatMessage({ id: 'kb' }));
    };
};
exports.useFileSizeFormatter = useFileSizeFormatter;
