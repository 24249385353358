"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.KnowledgeBase = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_intl_1 = require("react-intl");
var material_1 = require("@mui/material");
var common_1 = require("../../common");
var components_1 = require("../../common/components");
var App_1 = require("../App");
var SearchInput_1 = require("./SearchInput");
var OpenArticleIcon_1 = require("./OpenArticleIcon");
var ArticlePage_1 = require("./ArticlePage");
var styles = __importStar(require("./KnowledgeBase.module.css"));
var KnowledgeBase = function () {
    var intl = (0, react_intl_1.useIntl)();
    var _a = (0, react_1.useContext)(App_1.WidgetContext), api = _a.api, widgetSettings = _a.widgetSettings;
    var _b = (0, react_1.useState)([]), articles = _b[0], setArticles = _b[1];
    var _c = (0, react_1.useState)(null), articleId = _c[0], setArticleId = _c[1];
    var _d = (0, react_1.useState)(''), searchText = _d[0], setSearchQuery = _d[1];
    (0, react_1.useEffect)(function () {
        if (!api)
            return;
        api.getArticlesList().then(function (res) { return setArticles(res.items); });
    }, [api]);
    var handleSearch = (0, react_1.useCallback)(function (search) { return __awaiter(void 0, void 0, void 0, function () {
        var foundArticles;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!api)
                        return [2 /*return*/];
                    return [4 /*yield*/, api.findArticles(search)];
                case 1:
                    foundArticles = _a.sent();
                    setSearchQuery(search);
                    setArticles(foundArticles.items);
                    return [2 /*return*/];
            }
        });
    }); }, [api]);
    var redirectState = ((widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.blocks.includes('form')) || (widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.blocks.includes('chat'))) &&
        common_1.WIDGET_STATE.WRITE_TO_US_FORM;
    var accentColor = (widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.backgroundColor) || common_1.DEFAULT_BACKGROUND_COLOR;
    var articleItemTitleColor = (widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.fontColor) || common_1.DEFAULT_TEXT_COLOR;
    var articleItemTitleHoverColor = (widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.fontHoverColor) || accentColor;
    // Нужно т.к. в португальском и словацком header выходит очень длинным
    var additionalHeaderClass = intl.locale === 'pt-BR' || intl.locale === 'sk' ? styles.headerTextPt : '';
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: styles.container }, { children: [(0, jsx_runtime_1.jsx)(components_1.Header, __assign({ redirectState: redirectState, redirectText: intl.formatMessage({ id: 'contactUs' }), className: styles.knowledgeBaseHeader, classNameRedirect: styles.knowledgeBaseHeaderRedirect }, { children: (0, jsx_runtime_1.jsx)(material_1.Typography, __assign({ component: 'h5', className: "".concat(styles.headerText, " ").concat(additionalHeaderClass), color: '#FFF' }, { children: intl.formatMessage({ id: 'knowledgeBaseTitle' }) })) })), articleId ? ((0, jsx_runtime_1.jsx)(ArticlePage_1.ArticlePage, { articleId: articleId, arrowColor: accentColor, setArticleId: setArticleId })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(SearchInput_1.SearchInput, { onSearch: handleSearch, initSearchText: searchText }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: styles.wrapper }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: styles.knowledgeBaseList }, { children: articles === null || articles === void 0 ? void 0 : articles.map(function (article) { return ((0, jsx_runtime_1.jsxs)(material_1.Box, __assign({ onClick: function () { return setArticleId(article.id); }, className: styles.articleItem, component: 'div', sx: {
                                        color: articleItemTitleColor,
                                        ':hover': {
                                            color: articleItemTitleHoverColor,
                                            background: "linear-gradient(0deg, ".concat(accentColor, "1a, ").concat(accentColor, "1a), #FFFFFF"),
                                        },
                                    } }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ title: article.title }, { children: article.title })), (0, jsx_runtime_1.jsx)(OpenArticleIcon_1.ArticleArrowIcon, { color: accentColor })] }), article.id)); }) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: styles.footerWrapper }, { children: [(0, jsx_runtime_1.jsx)("div", { className: styles.footerUpperBackground }), (0, jsx_runtime_1.jsx)(components_1.Footer, { marginType: 'knowledgeBase' })] }))] }))] }))] })));
};
exports.KnowledgeBase = KnowledgeBase;
