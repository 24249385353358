"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.linkify = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
// Регулярное выражение для нахождения URL в тексте
var URL_REGEXP = /\bhttps?:\/\/\S+/gi;
var linkify = function (inputText) {
    // Если в тексте нет URL, просто возвращаем его
    if (!URL_REGEXP.test(inputText)) {
        return [inputText];
    }
    // Если в тексте есть URL, нужно сбросить позицию регулярного выражения
    // после использования метода test()
    URL_REGEXP.lastIndex = 0;
    // Массив для хранения разделенного текста и JSX-элементов ссылок
    var parts = [];
    var match;
    var lastIndex = 0;
    // Итерация по всем совпадениям URL в тексте
    while ((match = URL_REGEXP.exec(inputText)) !== null) {
        var link = match[0];
        var linkStartIndex = match.index;
        // Если URL не начинается сразу с начала строки, добавляем текст перед URL в parts
        if (linkStartIndex > lastIndex)
            parts.push(inputText.slice(lastIndex, linkStartIndex));
        // Создание JSX-элемент ссылки для каждого URL и добавьте его в parts
        parts.push((0, jsx_runtime_1.jsx)("a", __assign({ href: link, target: '_blank', rel: 'noreferrer noopener' }, { children: link }), link));
        // Обновляем lastIndex, чтобы он указывал на конец последнего найденного URL
        lastIndex = URL_REGEXP.lastIndex;
    }
    // Если текст остается после последнего URL, добавляем его в parts
    if (lastIndex < inputText.length)
        parts.push(inputText.slice(lastIndex));
    return parts;
};
exports.linkify = linkify;
