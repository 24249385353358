"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var SubmitIcon = function (_a) {
    var color = _a.color;
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: '20', height: '20', viewBox: '0 0 20 20', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: (0, jsx_runtime_1.jsx)("path", { d: 'M1.91729 0.993451C1.15492 0.629285 0.317334 1.32595 0.535042 2.14216L2.13104 8.10578C2.17104 8.25566 2.25419 8.39049 2.37016 8.49351C2.48614 8.59654 2.62982 8.66322 2.78337 8.68528L10.6082 9.80311C10.8346 9.83478 10.8346 10.1625 10.6082 10.195L2.78417 11.312C2.63062 11.3341 2.48693 11.4008 2.37095 11.5038C2.25498 11.6068 2.17183 11.7416 2.13183 11.8915L0.535042 17.8583C0.317334 18.6737 1.15492 19.3704 1.91729 19.007L18.9365 10.8932C19.6878 10.5354 19.6878 9.46507 18.9365 9.10645L1.91729 0.993451Z', fill: color }) })));
};
exports.SubmitIcon = SubmitIcon;
