"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReplayIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ReplayIcon = function (_a) {
    var color = _a.color;
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: '22', height: '18', viewBox: '0 0 22 18', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: (0, jsx_runtime_1.jsx)("path", { d: 'M13.7559 3.74391V1.75555C13.7559 0.642576 15.1065 0.0798341 15.8943 0.867672L21.6343 6.60764C21.7502 6.72333 21.8422 6.86075 21.9049 7.01203C21.9677 7.16331 22 7.32548 22 7.48927C22 7.65305 21.9677 7.81522 21.9049 7.9665C21.8422 8.11778 21.7502 8.2552 21.6343 8.37089L15.8943 14.1109C15.1065 14.8987 13.7559 14.3485 13.7559 13.2355V11.1221C7.50322 11.1221 3.12634 13.1229 0 17.4998C1.25054 11.2471 5.00215 4.99444 13.7559 3.74391Z', fill: color }) })));
};
exports.ReplayIcon = ReplayIcon;
