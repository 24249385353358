"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReplayBlock = exports.FilesBlock = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_intl_1 = require("react-intl");
var common_1 = require("../../../common");
var components_1 = require("../../../common/components");
var styles = __importStar(require("./Message.module.css"));
var FilesBlock = function (_a) {
    var message = _a.message, clientId = _a.clientId, setLoadedImages = _a.setLoadedImages;
    if (!message.files)
        return null;
    var onImageLoad = function () {
        setLoadedImages(function (prevCount) { return prevCount + 1; });
    };
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: message.files.map(function (file, index, filesArr) {
            var id = file.id, original_name = file.original_name, link = file.link;
            var fileLink = "".concat(link, "?clientId=").concat(clientId);
            if ((0, common_1.isImageFile)(original_name)) {
                var imgBlockStyle = void 0;
                if (!message.text && index === 0)
                    imgBlockStyle = { marginTop: '-10px' };
                if (index > 0 && (0, common_1.isImageFile)(filesArr[index - 1].original_name))
                    imgBlockStyle = { marginTop: '18px' };
                return ((0, jsx_runtime_1.jsx)("div", __assign({ className: styles.image, style: imgBlockStyle }, { children: (0, jsx_runtime_1.jsx)("a", __assign({ href: fileLink, download: original_name }, { children: (0, jsx_runtime_1.jsx)("img", { src: fileLink, alt: original_name, onLoad: onImageLoad }) })) }), id));
            }
            var fileBlockStyle;
            if (!message.text && index === 0)
                fileBlockStyle = { marginTop: '0px' };
            if (index > 0 && (0, common_1.isImageFile)(filesArr[index - 1].original_name))
                fileBlockStyle = { marginTop: '18px' };
            return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: styles.file, style: fileBlockStyle }, { children: [(0, jsx_runtime_1.jsx)(components_1.FileIcon, { color: message.channel === 'widget' ? '#FFF' : undefined }), (0, jsx_runtime_1.jsx)("a", __assign({ href: fileLink, download: original_name }, { children: (0, jsx_runtime_1.jsx)("span", __assign({ className: styles.fileName }, { children: (0, common_1.truncateMiddle)(original_name) })) }))] }), id));
        }) }));
};
exports.FilesBlock = FilesBlock;
/** Временно решили писать 'File' если текста в реплае нет,
 * т.к. чаще всего это будет пустое сообщение с каким-то файлом, но без доработки на бэке узнать
 * есть файл или нет нельзя.
 */
var ReplayBlock = function (_a) {
    var message = _a.message;
    var intl = (0, react_intl_1.useIntl)();
    if (!message.reply || message.reply.deleted_at)
        return null;
    var _b = message.reply, user_name = _b.user_name, text = _b.text;
    var lineColor = message.channel === 'widget' ? '#FFF' : common_1.DEFAULT_TEXT_COLOR;
    var lineHeight = user_name ? '40px' : '20px';
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: styles.replayContainer }, { children: [(0, jsx_runtime_1.jsx)("div", { className: styles.replayLine, style: { background: lineColor, height: lineHeight } }), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: styles.replayTitle }, { children: user_name })), (0, jsx_runtime_1.jsx)("div", __assign({ className: styles.replayContent }, { children: text || intl.formatMessage({ id: 'file' }) }))] })] })));
};
exports.ReplayBlock = ReplayBlock;
