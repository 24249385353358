// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inputContainer__st90E {\n  border-top: 1px solid #f3f3f3;\n}\n\n.inputWrapper__BSIHJ {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n\n  min-height: 60px;\n  padding-right: 12px;\n  padding-left: 20px;\n\n  background-color: white;\n}\n\n.textareaInput__jrT5c.textareaInput__jrT5c {\n  resize: none;\n\n  width: calc(100% - 80px);\n  padding: 0;\n\n  font-size: 16px;\n  line-height: 24px;\n\n  border: none;\n  outline: none;\n}\n\n.textareaInput__jrT5c::placeholder {\n  font-size: 16px;\n  line-height: 24px;\n  color: #8c8c8c;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Chat/ChatInput/ChatInput.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;;EAEnB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;;EAElB,uBAAuB;AACzB;;AAEA;EACE,YAAY;;EAEZ,wBAAwB;EACxB,UAAU;;EAEV,eAAe;EACf,iBAAiB;;EAEjB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".inputContainer {\n  border-top: 1px solid #f3f3f3;\n}\n\n.inputWrapper {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n\n  min-height: 60px;\n  padding-right: 12px;\n  padding-left: 20px;\n\n  background-color: white;\n}\n\n.textareaInput.textareaInput {\n  resize: none;\n\n  width: calc(100% - 80px);\n  padding: 0;\n\n  font-size: 16px;\n  line-height: 24px;\n\n  border: none;\n  outline: none;\n}\n\n.textareaInput::placeholder {\n  font-size: 16px;\n  line-height: 24px;\n  color: #8c8c8c;\n}\n"],"sourceRoot":""}]);
// Exports
export var inputContainer = "inputContainer__st90E";
export var inputWrapper = "inputWrapper__BSIHJ";
export var textareaInput = "textareaInput__jrT5c";
export default ___CSS_LOADER_EXPORT___;
