"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.it = void 0;
exports.it = {
    knowledgeBaseTitle: 'Base di conoscenza',
    contactUs: 'Contattaci',
    searchPlaceholder: 'Cerca aiuto',
    files: 'File',
    mb: 'MB',
    kb: 'KB',
    writeToUs: 'Scrivici',
    namePlaceholder: 'Qual è il tuo nome?',
    emailPlaceholder: 'E-mail',
    messagePlaceholder: 'Testo del messaggio',
    selectFiles: 'Seleziona file',
    orDropFiles: ' o trascinali qui',
    maxFileSizeError: 'La dimensione dei file supera il limite di {maxSize} MB',
    send: 'Invia',
    continue: 'Continua',
    back: 'Indietro',
    operator: 'Operatore',
    successTitle: 'Il tuo messaggio è stato inviato!',
    successText: 'Risponderemo presto',
    onlineStatus: 'Online',
    messageDeleted: 'Messaggio eliminato',
    replay: 'Rispondi',
    delete: 'Elimina',
    chatInputPlaceholder: 'Scrivi un messaggio...',
    deleteModalTitle: 'Elimina',
    deleteModalText: 'Sei sicuro di voler eliminare il messaggio?',
    cancel: 'Annulla',
    sizeExceededModalTitle: 'File troppo grande',
    sizeExceededModalText: "Non \u00E8 possibile inviare file superiori a {maxSize} MB",
    ok: 'Ok',
    file: 'File',
    footer: 'Fornito da ',
};
