"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ru = void 0;
exports.ru = {
    knowledgeBaseTitle: 'База знаний',
    contactUs: 'Написать нам',
    searchPlaceholder: 'Какой вопрос вас интересует?',
    files: 'Файлы',
    mb: 'МБ',
    kb: 'КБ',
    writeToUs: 'Написать нам',
    namePlaceholder: 'Как вас зовут?',
    emailPlaceholder: 'Электронная почта',
    messagePlaceholder: 'Текст сообщения',
    selectFiles: 'Выберите файлы',
    orDropFiles: ' или перетащите',
    maxFileSizeError: 'Допустимый объем прикрепляемых файлов - {maxSize} MB',
    send: 'Отправить',
    continue: 'Продолжить',
    back: 'Назад',
    operator: 'Оператор',
    successTitle: 'Ваше сообщение отправлено!',
    successText: 'Мы ответим вам в ближайшее время.',
    onlineStatus: 'Онлайн',
    messageDeleted: 'Сообщение удалено',
    replay: 'Ответить',
    delete: 'Удалить',
    chatInputPlaceholder: 'Написать сообщение...',
    deleteModalTitle: 'Удаление',
    deleteModalText: 'Вы уверены, что хотите удалить это сообщение?',
    cancel: 'Отмена',
    sizeExceededModalTitle: 'Слишком большой файл',
    sizeExceededModalText: "\u0414\u043E\u043F\u0443\u0441\u0442\u0438\u043C\u044B\u0439 \u043E\u0431\u044A\u0435\u043C \u0444\u0430\u0439\u043B\u043E\u0432 {maxSize} MB",
    ok: 'ОК',
    file: 'Файл',
    footer: 'Мы используем ',
};
