"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLanguage = void 0;
var locales_1 = require("../../../locales");
var getLanguage = function (initLang, application) {
    if (initLang && Object.keys(locales_1.localization).includes(initLang))
        return initLang;
    return application === 'Happydesk' ? 'ru' : 'en';
};
exports.getLanguage = getLanguage;
