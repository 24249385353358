"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WriteToUsForm = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_intl_1 = require("react-intl");
var material_1 = require("@mui/material");
var common_1 = require("../../common");
var components_1 = require("../../common/components");
var App_1 = require("../App");
var typedLocalStorage_1 = require("../../utils/typedLocalStorage");
var FileUpload_1 = require("./FileUpload");
var SuccessPage_1 = require("./SuccessPage");
var styles = __importStar(require("./WriteToUsForm.module.css"));
var WriteToUsForm = function (_a) {
    var isFullForm = _a.isFullForm;
    var intl = (0, react_intl_1.useIntl)();
    var _b = (0, react_1.useContext)(App_1.WidgetContext), widgetSettings = _b.widgetSettings, api = _b.api, socketService = _b.socketService, newUserData = _b.newUserData, redirectToState = _b.redirectToState, customSettings = _b.customSettings;
    var _c = (0, react_1.useState)(''), name = _c[0], setName = _c[1];
    var _d = (0, react_1.useState)(''), email = _d[0], setEmail = _d[1];
    var _e = (0, react_1.useState)(''), description = _e[0], setDescription = _e[1];
    var _f = (0, react_1.useState)([]), files = _f[0], setFiles = _f[1];
    var _g = (0, react_1.useState)(false), loading = _g[0], setLoading = _g[1];
    var _h = (0, react_1.useState)(false), showSuccessPage = _h[0], setShowSuccessPage = _h[1];
    (0, react_1.useEffect)(function () {
        var _a, _b;
        if (typedLocalStorage_1.typedLocalStorage === null || typedLocalStorage_1.typedLocalStorage === void 0 ? void 0 : typedLocalStorage_1.typedLocalStorage.getItem('userData')) {
            newUserData && setName((_a = JSON.parse(newUserData)) === null || _a === void 0 ? void 0 : _a.name);
            newUserData && setEmail((_b = JSON.parse(newUserData)) === null || _b === void 0 ? void 0 : _b.email);
            redirectToState(common_1.WIDGET_STATE.WRITE_TO_US_FORM);
        }
    }, [newUserData, redirectToState]);
    (0, react_1.useEffect)(function () {
        if (isFullForm && !(widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.disableGoogleCaptcha)) {
            (0, common_1.loadRecaptchaScript)();
            return function () { return (0, common_1.removeRecaptcha)(); };
        }
    }, [isFullForm]);
    var onSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var token, response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    e.preventDefault();
                    setLoading(true);
                    if (!(isFullForm && api)) return [3 /*break*/, 3];
                    return [4 /*yield*/, ((_a = window.grecaptcha) === null || _a === void 0 ? void 0 : _a.execute(common_1.RECAPTCHA_KEY_ID, { action: 'submit' }))];
                case 1:
                    token = _b.sent();
                    return [4 /*yield*/, (api === null || api === void 0 ? void 0 : api.createIssue({
                            name: name,
                            email: email,
                            description: description,
                            custom_fields: customSettings === null || customSettings === void 0 ? void 0 : customSettings.customFields,
                            files: files.map(function (file) { return file.file; }),
                            g_recaptcha_response: token,
                        }).finally(function () { return setLoading(false); }))];
                case 2:
                    response = _b.sent();
                    response && setShowSuccessPage(true);
                    _b.label = 3;
                case 3:
                    if (!isFullForm && socketService)
                        socketService.registerChat({ name: name, email: email });
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var redirectState = (widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.blocks.includes('knowledge')) && common_1.WIDGET_STATE.KNOWLEDGE_BASE;
    var writeToUsTitle = (widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.formTitle) || intl.formatMessage({ id: 'writeToUs' });
    var sendButtonTitle = !isFullForm
        ? intl.formatMessage({ id: 'continue' })
        : (widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.formButtonTitle) || intl.formatMessage({ id: 'send' });
    var backgroundColor = (widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.backgroundColor) || common_1.DEFAULT_BACKGROUND_COLOR;
    var buttonBackgroundColor = (widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.formButtonColor) || backgroundColor;
    var buttonBackgroundColorHover = (widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.formButtonHoverColor) || backgroundColor;
    var buttonTextColor = (widgetSettings === null || widgetSettings === void 0 ? void 0 : widgetSettings.formButtonTitleColor) || backgroundColor;
    var isDisabled = name.trim() === '' || !(0, common_1.isValidEmail)(email) || (isFullForm && description.trim() === '');
    var disableEmailAndNameField = (customSettings === null || customSettings === void 0 ? void 0 : customSettings.isDisabledNameAndEmail) !== undefined && (customSettings === null || customSettings === void 0 ? void 0 : customSettings.isDisabledNameAndEmail);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: styles.container }, { children: [(0, jsx_runtime_1.jsx)(components_1.Header, __assign({ redirectState: redirectState, redirectText: intl.formatMessage({ id: 'back' }), className: styles.writeToUsHeader, classNameRedirect: styles.writeToUsHeaderRedirect }, { children: (0, jsx_runtime_1.jsx)(material_1.Typography, __assign({ component: 'h4', className: styles.headerText }, { children: writeToUsTitle })) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: styles.formContainer }, { children: [showSuccessPage ? ((0, jsx_runtime_1.jsx)(SuccessPage_1.SuccessPage, {})) : ((0, jsx_runtime_1.jsxs)(material_1.Container, __assign({ component: 'form', onSubmit: onSubmit }, { children: [(0, jsx_runtime_1.jsx)(material_1.Input, { className: styles.inputField, placeholder: intl.formatMessage({ id: 'namePlaceholder' }), value: name, onChange: function (e) { return setName(e.target.value); }, disableUnderline: true, disabled: disableEmailAndNameField }), (0, jsx_runtime_1.jsx)(material_1.Input, { className: styles.inputField, placeholder: intl.formatMessage({ id: 'emailPlaceholder' }), value: email, onChange: function (e) { return setEmail(e.target.value); }, type: 'email', disableUnderline: true, disabled: disableEmailAndNameField }), (0, jsx_runtime_1.jsx)(material_1.TextareaAutosize, { className: styles.textareaField, placeholder: intl.formatMessage({ id: 'messagePlaceholder' }), value: description, onChange: function (e) { return setDescription(e.target.value); }, hidden: !isFullForm }), (0, jsx_runtime_1.jsx)(FileUpload_1.FileUpload, { files: files, setFiles: setFiles, color: backgroundColor, hidden: !isFullForm }), (0, jsx_runtime_1.jsxs)(material_1.Button, __assign({ type: 'submit', variant: 'contained', className: styles.sendButton, sx: {
                                    color: buttonTextColor,
                                    background: buttonBackgroundColor,
                                    ':hover': { background: buttonBackgroundColorHover },
                                    ':disabled': { color: "".concat(backgroundColor, "66") } /* 66 - добавляет прозрачность 40% */,
                                }, disabled: isDisabled || loading }, { children: [sendButtonTitle, loading && (0, jsx_runtime_1.jsx)(material_1.CircularProgress, { color: 'inherit', size: 15 })] }))] }))), (0, jsx_runtime_1.jsx)(components_1.Footer, {})] }))] })));
};
exports.WriteToUsForm = WriteToUsForm;
