// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/background.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper__SFoS8 {\n  height: 100vh;\n\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n  mix-blend-mode: overlay;\n}\n\n.wrapper__SFoS8::before {\n  pointer-events: none; /* To allow interaction with the content below the gradient */\n  content: '';\n\n  position: absolute;\n  z-index: -1;\n  top: 0;\n  left: 0;\n\n  width: 100%;\n  height: 100%;\n\n  background-image: linear-gradient(180deg, var(--background) 0%, rgb(0 209 255 / 0%) 100%);\n  mix-blend-mode: overlay;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/App.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;;EAEb,yDAA2D;EAC3D,4BAA4B;EAC5B,2BAA2B;EAC3B,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,oBAAoB,EAAE,6DAA6D;EACnF,WAAW;;EAEX,kBAAkB;EAClB,WAAW;EACX,MAAM;EACN,OAAO;;EAEP,WAAW;EACX,YAAY;;EAEZ,yFAAyF;EACzF,uBAAuB;AACzB","sourcesContent":[".wrapper {\n  height: 100vh;\n\n  background-image: url('../../assets/images/background.svg');\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n  mix-blend-mode: overlay;\n}\n\n.wrapper::before {\n  pointer-events: none; /* To allow interaction with the content below the gradient */\n  content: '';\n\n  position: absolute;\n  z-index: -1;\n  top: 0;\n  left: 0;\n\n  width: 100%;\n  height: 100%;\n\n  background-image: linear-gradient(180deg, var(--background) 0%, rgb(0 209 255 / 0%) 100%);\n  mix-blend-mode: overlay;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = "wrapper__SFoS8";
export default ___CSS_LOADER_EXPORT___;
