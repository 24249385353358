"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArticleArrowIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ArticleArrowIcon = function (_a) {
    var color = _a.color;
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: '8', height: '14', viewBox: '0 0 8 14', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: (0, jsx_runtime_1.jsx)("path", { d: 'M1.91666 13.0667L7.53333 7.46668C7.6 7.40002 7.64733 7.32779 7.67533 7.25002C7.70333 7.17224 7.71711 7.08891 7.71666 7.00002C7.71666 6.91113 7.70266 6.82779 7.67466 6.75002C7.64666 6.67224 7.59955 6.60002 7.53333 6.53335L1.91666 0.916683C1.76111 0.761127 1.56666 0.68335 1.33333 0.68335C1.1 0.68335 0.899998 0.766683 0.733331 0.93335C0.566665 1.10002 0.483331 1.29446 0.483331 1.51668C0.483331 1.73891 0.566665 1.93335 0.733331 2.10002L5.63333 7.00002L0.733331 11.9C0.577776 12.0556 0.499998 12.2473 0.499998 12.4753C0.499998 12.7033 0.583331 12.9005 0.749998 13.0667C0.916665 13.2334 1.11111 13.3167 1.33333 13.3167C1.55555 13.3167 1.75 13.2334 1.91666 13.0667Z', fill: color }) })));
};
exports.ArticleArrowIcon = ArticleArrowIcon;
