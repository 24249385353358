"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initHandleFrameMessages = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
var api_1 = require("../../../api");
var common_1 = require("../../../common");
var typedLocalStorage_1 = require("../../../utils/typedLocalStorage");
var socketService_1 = require("./socketService");
var widgetSettingsMapper_1 = require("./widgetSettingsMapper");
var initHandleFrameMessages = function (_a) {
    var setWidgetSettings = _a.setWidgetSettings, setApi = _a.setApi, setCurrentWidgetState = _a.setCurrentWidgetState, setSocketService = _a.setSocketService, setNewUserData = _a.setNewUserData, setCustomSettings = _a.setCustomSettings;
    var socketService;
    return function (msg) {
        var _a = msg.data, serverParams = _a.serverParams, visualParams = _a.visualParams, userData = _a.userData, user = _a.user, isDisabledNameAndEmail = _a.isDisabledNameAndEmail, customFields = _a.customFields;
        if (isDisabledNameAndEmail !== undefined) {
            setCustomSettings(function (prev) { return (__assign(__assign({}, prev), { isDisabledNameAndEmail: isDisabledNameAndEmail })); });
        }
        if (customFields !== undefined) {
            setCustomSettings(function (prev) { return (__assign(__assign({}, prev), { customFields: customFields })); });
        }
        if (visualParams) {
            var widgetSettings = visualParams && widgetSettingsMapper_1.widgetSettingsMapper.mapFrom(visualParams.params);
            var clientId = serverParams === null || serverParams === void 0 ? void 0 : serverParams.initParamData.clientId;
            clientId && setApi((0, api_1.initApi)(clientId));
            widgetSettings &&
                setWidgetSettings(__assign(__assign({}, widgetSettings), { language: serverParams === null || serverParams === void 0 ? void 0 : serverParams.user.language, application: (serverParams === null || serverParams === void 0 ? void 0 : serverParams.host.includes('happydesk')) ? 'Happydesk' : 'Pulsedesk', host: serverParams === null || serverParams === void 0 ? void 0 : serverParams.host }));
        }
        if (visualParams === null || visualParams === void 0 ? void 0 : visualParams.params.blocks.length) {
            var blocks = visualParams.params.blocks;
            if (blocks.includes('knowledge'))
                setCurrentWidgetState(common_1.WIDGET_STATE.KNOWLEDGE_BASE);
            else
                setCurrentWidgetState(common_1.WIDGET_STATE.WRITE_TO_US_FORM);
        }
        if (serverParams && (visualParams === null || visualParams === void 0 ? void 0 : visualParams.params.blocks.includes('chat'))) {
            socketService = new socketService_1.SocketService(serverParams);
            setSocketService(socketService);
        }
        if (userData && userData.type === 'userData') {
            var userAccount = { email: userData.email, name: userData.name };
            typedLocalStorage_1.typedLocalStorage.setItem('userData', JSON.stringify(userAccount));
            socketService && (socketService.joinResponseData = null);
            setNewUserData(JSON.stringify(userAccount));
        }
        if (user === null || user === void 0 ? void 0 : user.page_url) {
            typedLocalStorage_1.typedLocalStorage.setItem('refererHost', user.page_url);
        }
    };
};
exports.initHandleFrameMessages = initHandleFrameMessages;
