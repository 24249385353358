// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".successWrapper__sKQPn {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n\n  margin-top: 44px;\n}\n\n.successTitle__9H3yb {\n  margin: 0;\n  margin-top: 24px;\n\n  font-size: 22px;\n  font-weight: 600;\n  line-height: 28px;\n}\n\n.successText__A1Seg {\n  margin: 0;\n  margin-top: 8px;\n\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n}\n\n.successTitle__9H3yb,\n.successText__A1Seg {\n  color: #fff;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/WriteToUsForm/SuccessPage/SuccessPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,2BAA2B;;EAE3B,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,gBAAgB;;EAEhB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,eAAe;;EAEf,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;;EAEE,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".successWrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n\n  margin-top: 44px;\n}\n\n.successTitle {\n  margin: 0;\n  margin-top: 24px;\n\n  font-size: 22px;\n  font-weight: 600;\n  line-height: 28px;\n}\n\n.successText {\n  margin: 0;\n  margin-top: 8px;\n\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n}\n\n.successTitle,\n.successText {\n  color: #fff;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var successWrapper = "successWrapper__sKQPn";
export var successTitle = "successTitle__9H3yb";
export var successText = "successText__A1Seg";
export default ___CSS_LOADER_EXPORT___;
