"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addCustomFieldsInFormData = void 0;
var addCustomFieldsInFormData = function (formData, issueData) {
    if (Array.isArray(issueData.custom_fields)) {
        issueData.custom_fields.forEach(function (field) {
            for (var key in field) {
                formData.append("custom_fields[".concat(key, "]"), field[key]);
            }
        });
    }
    else {
        for (var key in issueData.custom_fields) {
            formData.append("custom_fields[".concat(key, "]"), issueData.custom_fields[key]);
        }
    }
};
exports.addCustomFieldsInFormData = addCustomFieldsInFormData;
