"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sk = void 0;
exports.sk = {
    knowledgeBaseTitle: 'Databáza znalostí',
    contactUs: 'Kontaktujte nás',
    searchPlaceholder: 'Vyhľadanie pomoci',
    files: 'Súbory',
    mb: 'MB',
    kb: 'KB',
    writeToUs: 'Napíšte nám',
    namePlaceholder: 'Ako sa voláte?',
    emailPlaceholder: 'Emailová adresa',
    messagePlaceholder: 'Text správy',
    selectFiles: 'Vyberte súbory',
    orDropFiles: ' alebo ich vložte sem',
    maxFileSizeError: 'Veľkosť súborov presiahla {maxSize} MB limit',
    send: 'Poslať',
    continue: 'Pokračovať',
    back: 'Späť',
    operator: 'Operátor',
    successTitle: 'Vaša správa bola odoslaná!',
    successText: 'Čoskoro vám odpovieme',
    onlineStatus: 'Aktívny',
    messageDeleted: 'Správa bola vymazaná',
    replay: 'Odpovedať',
    delete: 'Odstrániť',
    chatInputPlaceholder: 'Napíšte správu...',
    deleteModalTitle: 'Odstránenie',
    deleteModalText: 'Ste si istí, že chcete správu odstrániť?',
    cancel: 'Zrušiť',
    sizeExceededModalTitle: 'Veľmi veľký súbor',
    sizeExceededModalText: "Nie je mo\u017En\u00E9 posla\u0165 s\u00FAbory v\u00E4\u010D\u0161ie ako {maxSize} MB",
    ok: 'Ok',
    file: 'Súbor',
    footer: 'Prináša ',
};
